.AuditSpaceAdd_content {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.AuditSpaceAdd_card {
  width: 1000px;
  max-width: 95vw;
  padding: 10px;
}

.AuditHome_add_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 10px;
}
