.AuditList_content {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.AuditList_sub_div {
    width: 1000px;
    max-width: 95vw;
}

.AuditList_sub_div_date_picker_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}
.AuditList_sub_div_date_picker_div_picker {
    width: 50%;
    max-width: 200px;
}

.AuditList_selected_bookings_list_title {
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
}