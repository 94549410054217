.AddAsset {
    position: relative;
    height: calc(100vh - 70px);
    
}

.AddAsset  .progress {
    width: calc(100% - 20px);
    margin-left: 10px;
    color: rgb(18, 100, 100);
}

.AddAsset .slide {
    width: calc(100% - 20px);
    margin-left: 10px;
    height: calc(100% - 50px);
}



.AddAsset .nav-buttons {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

}
