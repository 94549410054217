.AuditHome_master_content_div {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
  position: relative;
}

.AuditHome-loading {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
  top: 0px;
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
}




.AuditHome_master_content_card {
  width: 1000px;
  max-width: 95vw;
}

.AuditHome_top_div_a_i {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 20px);
  margin-left: 5px;
}
.AuditHome_sub_card {
    width: calc(50% - 20px);
    margin-left: 10px;
    padding: 10px;
    margin-top: 10px;
    height: fit-content;
  }

@media screen and (max-width: 700px) {
  .AuditHome_top_div_a_i {
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);
  }
  .AuditHome_sub_card {
    width: calc(100% - 40px);
    margin-left: 10px;
    padding: 10px;
    margin-top: 10px;
  }
}

.AuditHome_sub_heading {
  font-weight: 600;
  border-bottom: solid;
  border-width: 1px;
  border-color: lightgray;
  padding-bottom: 5px;
}


.AuditHome_sub_card_bottom {
    width: calc(100% - 40px);
    margin-left: 10px;
    padding: 10px;
    margin-top: 10px; 
}

.AuditHome_notes_message {
    padding: 10px;
}

.AuditHome_address_display {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.AuditHome_address_display_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.AuditHome_address_display_left_rotate {
    transform: rotate(-30deg);
}


.AduitHome_address_line {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font-weight: 500;
    padding: 2px;
}

.AuditHome_image_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.AuditHome_image_img {
    width: calc(100% - 10px);
    margin-top: 10px;
    max-height: 300px;
    object-fit: contain;
    
}


.auditHomeStartButton {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-content: right;
    align-items: right;
}


.auditHomeStartButton .fab-section {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: inherit;
}




.AuditHome_master_content_div .fab-buttons {
  position: fixed;
  bottom: 10px;
  right: 20px;
  transition: ease 1s;
}

.AuditHome_master_content_div .fab-buttons-hidden {
  position: fixed;
  bottom: -160px;
  right: 20px;
  transition: ease 1s;
}

.AuditHome_master_content_div .arrow-button {
  transform: rotate(90deg);
  transition: ease 1s;


}
.AuditHome_master_content_div  .arrow-button-shown { 
  transform: rotate(-90deg);
  transition: ease 1s;
}

.AuditHome_master_content_div  .actions {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 180px;
}






