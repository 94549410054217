.Header_body { 
    width: 100vw;
    height: 60px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.header_body_left {
    margin-left: 10px;
}

.header_body_left_img_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;

}

.header_body_left_img_div_img { 
    width: 50vw;
    max-width: 200px;
}

.header_body_right {
      margin-right: 10px;
    width: 50vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.header_body_right_sub {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.nav-menu {
    background-color: rgb(18, 100, 100);
    width: 240px;
    height: 100vh;
    position: fixed;
    top: 0;
    transition: 850ms;
    right: -100%;
    box-shadow: 0px 5px 8px -9px silver;
    z-index: 1;
    overflow: scroll;
    z-index: 10000;

    
}

.nav-menu.active {
    right: 0;
    transition: 350ms;
}

.sidebar_topbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: calc(100% - 10px);
}

.header_sidebar_list {
    width: calc(100% - 20px);
    margin-left: 10px;
    max-height: 85vh;

}


.header_listItem {
    width: 100%;
    color:'white';
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header_listItem :hover {
    cursor: pointer;
}

.header_listItem_icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

}

.header_listItem_text {
    color: white;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

}


.header_page_title {
    display: flex;
    flex-direction: row;
    width: calc(100vw - 20px);
    justify-content: flex-end;
    margin-left: 10px;
    font-weight: 600;
    padding-bottom: 5px;
}