.AssetAdd_half_field {
    width: 50%;
}

.AssetAdd_half_fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.AssetAdd_half_field_30 {
    width: 35%;

}

.AssetAdd_half_field_70 {
    width: 64%;

}

.AssetAdd_fab {
    position: sticky;
    bottom: 10px;
    width: calc(100vw - 10px);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.SpaceAdd_fab {
    position: sticky;
    margin-left: 20px;
    

}