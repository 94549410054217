.SpaceAdd_measurments {
  width: 49%;
  max-width: 200px;
}

.SpaceAdd_measurment_section {
  display: flex;
  flex-direction: row;
}

.SpaceAdd_measurments2 {
  width: 49%;
  max-width: 200px;
  padding-left: 10px;
}

.AuditHome_asset_add {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
}

.AuditHome_img_parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.AuditHome_img {
  width: 400px;
  margin-left: 5px;
  margin-right: 5px;


}

.SpaceAdd_images {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    flex-flow: nowrap !important;
    width: 100%;
}

.dialog_box_select_file {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.SpaceAdd_loading_background { 
    width: 100%;
    height: 100vh;
    background-color:rgba(255, 255, 255, 0.7);
    position:fixed;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    z-index: 10000;

}
.SpaceAdd_loading_sub { 

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

.SpaceAdd_HEader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    width: calc(100% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    font-weight: 600;
}
