
.slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit;
    position: relative;
}

.slide .loading {
    position: absolute;
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}



.AddAsset .loading {
    position: absolute;
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 500;
}



.slide-photos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: inherit;
    position: relative;
    

}


.upload-fab {
    position: absolute;
    bottom: 0px;
    right: 0px;

}

.slide .title { 
    font-size: medium;
    width: 100%;
    border-bottom: solid 1px lightgray;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.slide .fullwidth {
    margin-top: 10px;
    margin-bottom: 5px;
}

.slide .thirty-seventy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%
}

.slide .thirty {
    width: 29%;
    
}
.slide .seventy {
    width: 68%;
}




.Review  .section .subtitle  {
   margin-bottom: 10px;
   padding-bottom: 3px;
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   padding-top: 10px;
}

.Review  .section .subtitle .text  {
    white-space: nowrap;
    padding-right: 10px;
  }

.Review  .section .subtitle .line  {
   background-color: lightgray;
   height: 1px;
   width: 100%;
 }



.Review {
    height: 100%;
    overflow: scroll;
    padding-top: 20px;
    padding-bottom: 20px;
}


.slide-photos .loading {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(265, 265, 265, 0.7);
    z-index: 10000;
}


.photos-uploaded {
    height: 110%;
    overflow: scroll;
}

.photos-uploaded .image img { 
    width: 100%;
    object-fit: contain;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 5px;
    margin-bottom: 10px;
    max-height: 400px;
   

}
.photos-uploaded .image {
    position: relative;
}

.photos-uploaded .image .icon-button {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 5;
}

