.Login_master_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
}

.Login_sub_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-width: 500px;
    width: 80%;
}


.Login_card { 
    width: 100%;
    padding: 10px;
}

.Login_card_logo_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 10px;
}

.Login_card_subheading {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: 700;
    font-size: large;
    padding-bottom: 20px;
}
.Login_card_img {
    width: 80%;
}

.Login_card_submit_div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 10px;
    padding-top: 10px;
}